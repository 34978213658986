import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import axios from "axios";

export const GetAlias = (id) => {
	const data = useStaticQuery(graphql`
    query {
	glstrapi {
		allMenus {
			URL
			Label
			Template
			Alias
			id
			Secondary_URL
		}
	}
    }
  `);

	let PageURL = data.glstrapi.allMenus.filter(item => item.id === id).pop();


    let URL = PageURL?.Alias

    return URL;

}