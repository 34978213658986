import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import parse from 'html-react-parser';
import { Container, Row, Col, Form } from "react-bootstrap"
import LogoGroup from "../../images/logo-group.svg";
import "./Footer.scss";
import GenerateLink from "../common/site/generate-link"
import SiteLogo from "../../images/starberry-logo.png"
import Logo1 from "../../images/footer-brands/Arla_White.svg"
import Logo2 from "../../images/footer-brands/TPO-Property-Ombudsman-White.svg"
import Logo3 from "../../images/footer-brands/TSI-Trading_Standards-White.png"
import axios from 'axios';

const Footer = (props) => {

  useEffect(() => {
    // setTimeout(() => { 
    //   window.gapi.load('client:auth2', () => {
    //     console.log('load');
    //     window.gapi.client.init({
    //       // 'apiKey': 'AIzaSyAw-31MrM500_oZmNTfy8HHTK9PgNV12As',
    //       // clientId and scope are optional if auth is not required.
    //       'clientId': '516043153784-tugiptkibtgn34v6v2hf5tu3iutqsiq9.apps.googleusercontent.com', 
    //       'scope': ['https://www.googleapis.com/auth/plus.business.manage', 'https://www.googleapis.com/auth/business.manage'],
    //     }).then(function () {
    //       // 3. Initialize and make the API request.
    //       console.log('clet')
    //       return window.gapi.client.request({
    //         'path': 'https://mybusiness.googleapis.com/v4/accounts/',
    //       })
    //     }), function (reason) {
    //       console.log(reason);
    //     });

    //   });
    // }, 5000) 

    // axios.post('https://oauth2.googleapis.com/token', {
    //   client_id: '516043153784-tugiptkibtgn34v6v2hf5tu3iutqsiq9.apps.googleusercontent.com',
    //   client_secret: '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',
    //   grant_type: 'refresh_token',
    //   refresh_token: '1//04TI9vXym7s24CgYIARAAGAQSNwF-L9IrXg0xaZpQMXGtARq7Lfh8O5MGOZlF6K519p0AtPXtiBgTIxyrhCMkn14aCDova84NphE'
    // })
    //   .then(function (response) {
    //     console.log(response.data);
    //     axios.get('https://mybusiness.googleapis.com/v4/accounts/113621087129739481514/locations/16437843973147198048/reviews',{
    //       headers: { "Authorization": `Bearer ${response.data.access_token}` }
    //     }).then(function (response) {
    //       console.log(response)
    //     })
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   }); 

  });

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menusFooter {
        Add_Footer_Menus {
          Label
          Link {
            id
          }
          Menus {
            Label
            Link {
              id
            }
          }
        }
      }

      siteConfig {
        Youtube_Link
        Twitter_Link
        Instagram_Link
        Facebook_Link
        Footer_Bottom_Content
        Footer_Bottom_Menus {
          Label
          Link {
            id
          }
        }
      }

    }
  }
`);


  useEffect(() => {
    // const script = document.createElement('script');

    // script.src = "https://clients.yomdel.com/tools/chat_script.js?url=" + document.location.href
    // script.defer = true;
    // script.async = true;

    // document.body.appendChild(script);

    const script_one = document.createElement('script');

    script_one.src = "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
    script_one.defer = true;
    script_one.async = true;
    script_one.setAttribute("data-domain-script", "c3ef871f-9a88-426b-b804-3bab3258b697-test")

    document.body.appendChild(script_one);
    function OptanonWrapper() { }


  }, [])


  const menus = data.glstrapi.menusFooter;
  const site = data.glstrapi.siteConfig;

  const [search, setSearch] = useState(true);
  const [state, setState] = useState([])
  const d = new Date();
  let year = d.getFullYear();
  const openSearch = () => {
    setSearch(!search)
  }

  const clickHandler = (index) => {
    let newState = [...state];
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    let isActive = a[index].isActive;
    a[index].isActive = !isActive;
    setState(a);
  }

  return (

    <React.Fragment>
      <footer className="footer">
        <Container>
          {/* <div className="footer-link-block">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="footer-left">
                <Row className="footer-block1 footer-quick-links">
                  {menus.Add_Footer_Menus.map((item, i) => (
                    <FooterMenu
                      i={i}
                      clickHandler={clickHandler}
                      {...item} />
                  ))}
                </Row>
              </div>

            </ScrollAnimation> 
          </div> */}
          <Row>
            <Col md="12" lg="6">
              {/* <div class="g-signin2" data-onsuccess="onSignIn"></div> */}
              <div className="social-icons">
                <ul>
                  {site.Facebook_Link &&
                    <li>
                      <a href={site.Facebook_Link} className="" title="facebook" target="_blank">
                        <i className="icon-facebook"></i>
                      </a>
                    </li>
                  }
                  {site.Instagram_Link &&
                    <li>
                      <a href={site.Instagram_Link} className="" title="instagram" target="_blank">
                        <i className="icon-insta"></i>
                      </a>
                    </li>
                  }
                  {site.Twitter_Link &&
                    <li>
                      <a href={site.Twitter_Link} className="" title="twitter" target="_blank">
                        <i className="icon-twitter"></i>
                      </a>
                    </li>
                  }
                  {site.Youtube_Link &&
                    <li>
                      <a href={site.Youtube_Link} className="" title="youtube" target="_blank">
                        <i className="icon-youtube"></i>
                      </a>
                    </li>
                  }
                </ul>
              </div>
            </Col>
            <Col md="12" lg="6">
              <div className="d-flex justify-content-evenly footer-bottom-logos">
              <img loading="lazy" src={Logo1} width="150" height="75" alt="" className="group-logo" />
                <img loading="lazy" src={Logo2} width="150" height="52" alt="" className="group-logo" />
                <img loading="lazy" src={Logo3} width="150" height="75" alt="" className="group-logo" />

              </div> 
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="footer-link footer-link-wrap">
                <ul>
                  {site.Footer_Bottom_Menus.map((item, i) => (
                    <li>
                      <GenerateLink link={item.Link}>{item.Label}</GenerateLink>
                      {site.Footer_Bottom_Menus.length != (i + 1) && <> |</>}
                    </li>
                  ))}
                </ul>
                <p className="footer-text">{year} Arun Estates. All Rights Reserved.</p>
                <div className="footer-content">
                  {parse(site.Footer_Bottom_Content)}
                </div>
                <p>Site by <Link href="https://starberry.tv/" target="_blank" className="starberry"><img src={SiteLogo} alt="logo" width="21" height="20" />  Starberry</Link></p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer;