import React from "react";
import { Link } from "gatsby"

import { GetURL } from "./functions";
import { GetAlias } from "./GetAlias";


const GenerateLink = (props, { children }) => {
	let url = ''
	let page_url = ''
	let alias = ''
	if (props.link) {
		url = GetURL(props.link.id)
		alias = GetAlias(props.link.id)
		page_url = `/`+url+`/`
	}
	function localstorageclear() {
		if (typeof window !== 'undefined') {
			localStorage.setItem("searchValue", "")
			localStorage.setItem("searchValueFull", "")
			localStorage.setItem("searchValueFullName", "")
		}
	}
	return (
		<React.Fragment>
			{url.indexOf("http://") == 0 || url.indexOf("https://") == 0 ?
				<a href={`${url}`} target="_blank" className={props.class}>{props.children}</a>
				: <Link to={page_url.replace('//','/')} className={props.class} onClick={() => { localStorage.setItem('page-alias', alias); localstorageclear(); }}>{props.children}</Link>}
		</React.Fragment>
	)
} 

export default GenerateLink;
