import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Form, Button } from "react-bootstrap";
import loadable from '@loadable/component'
import GenerateLink from "../common/site/generate-link"
import $ from 'jquery'
import { navigate } from "@reach/router"
// import SearchLoqateAddress from '../forms/elements/address-search-header'


// const SearchLoqateAddress = loadable(() => import('../forms/elements/address-search-header'))


const MegaMenu = (props) => {

  // let url = "/";

  // useEffect(() => {
  // $(".search-btn").click(function() {
  //   var searcValue = $(".address").val()
  //   url = "/property/for-sale/"
  //   if(searcValue !== "") {
  //       url += "in-"+searcValue+"/"
  //   } else {
  //       url += "in-kent/"
  //   }
  //   navigate(url);
  // })
  // }) 
  var classn = ''
  if(props.data && props.data.Add_Sub_Menus) {
    classn = props.data.Add_Sub_Menus.length
  }
  return (
    <div className={`menu-dropdown ${classn == "1" ? 'one-col' : ''} ${props.data.Add_Sub_Menus?.length == 2 ? `${props.data.Label.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replace(/ /g, "")} two` : ''}`}>
      {props.data?.In_Submenu_Show_Search &&
        <div className="title">
          {props.data?.Submenu_Search_Title} 
        </div>
      }
      <div className="dropdown-link-list">
        {props.data && props.data.Add_Sub_Menus.map((item, i) => (
          <div className="dropdown-box">
            {item.Title &&
              <span>{item.Title}</span>
            }
            <ul>
              {item.Add_here.map((menus, i) => (
                <li>
                  <GenerateLink link={menus.Submenu_Link}>{menus.Submenu_Label}</GenerateLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MegaMenu;
