import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Nav, Container, Row, Col } from "react-bootstrap";
import GenerateLink from "../common/site/generate-link"
// Images
import BrandLogo from "../../images/logo.svg";
import BrandLogoDark from "../../images/logo-dark.svg";
import "./Header.scss";
import MegaMenu from './MegaMenu';
import MenuData from './MenuData';
import Helmet from 'react-helmet'
import { setUtmCookie } from '../common/site/cookie';

const Header = (props) => {


  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menusHeaders(sort: "Sort:asc") {
        id
        Label
        Link {
          id
        }
        Sort
        Submenu_Search_Title
        In_Submenu_Show_Search
        Add_Sub_Menus {
          Title
          Add_here {
            Submenu_Label
            Submenu_Link {
              id
            }
          }
        }
      }

    }
  }
`);

  const menus = data.glstrapi.menusHeaders;

  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [state, setState] = useState([]);
  const careerUrl = process.env.GATSBY_CAREER_SITE_URL;

  useEffect(() => {
    setUtmCookie();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
    if (menus && menus.length > 0) {
      var mapMenuList = []
      menus.map((item, i) => {
        if (item.Add_Sub_Menus.length > 0) {
          var menu = {
            name: item.Label,
            link: item.Link,
            isActive: false,
            submenu: []
          }
          item.Add_Sub_Menus.map((submenu, i) => {
            submenu.Add_here.map((innermenu, i) => {
              menu.submenu.push({
                name: innermenu.Submenu_Label,
                link: innermenu.Submenu_Link,
                isActive: false,
              })
            })
          })
          mapMenuList.push(menu)
        } else {
          mapMenuList.push({
            name: item.Label,
            link: item.Link,
            isActive: false,
          })
        }
      })
      setState(mapMenuList)
    }
  }, []);

  const showMenu = () => {
    setShow(!show);
    // document.querySelector("body").classList.add("overflow-hidden");

  };
  const handlerOpenMenu = (index) => {
    let newState = [...state];
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    let isActive = a[index].isActive;
    a[index].isActive = !isActive;
    setState(a);
  };

  const handlerOpenSubMenu = (e, index, i) => {
    e.stopPropagation();
    let newState = [...state];
    let a = newState[index].submenu.map((item, j) => {
      if (i !== j) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    newState[index].submenu = a;
    let isActive = newState[index].submenu[i].isActive;
    newState[index].submenu[i].isActive = !isActive;
    setState(newState);
  };

  const closeMenu = () => {
    setShow(!show);
    document.querySelector("body").classList.remove("overflow-hidden");
    setState(
      state.map(menu => {
        if (Array.isArray(menu.submenu)) {
          let p = menu.submenu.map(sub => ({ ...sub, ...{ isActive: false } }));
          return ({ ...menu, ...{ submenu: p }, ...{ isActive: false } })
        } else {
          return ({ ...menu, ...{ isActive: false } })
        }
      })
    )
  }

  return (
    <>
      <header className={scroll && props.type != "results" ? "header--fixed header" : scroll && props.type == "results" ? "header results-header" : "header"}>
        <Helmet>
          <script defer src="https://apis.google.com/js/platform.js?onload=loadAuthClient" async ></script>
        </Helmet>
        <Container fluid>
          <Row>
            <Col>
              <Nav>
                <div className="header-wrapper">
                  <div className="logo">
                    <Link to="/">
                      <img src={BrandLogo} alt="logo" width="108" className="light-logo" />
                      <img src={BrandLogoDark} className="dark-logo" alt="logo" width="108" />
                    </Link>
                  </div>


                  <div className="nav-right ml-auto d-flex d-lg-block">
                    {/* Top Nav */}
                    <div className="d-none d-lg-block menu-wrap all-menu-list">
                      <ul>
                        <li>
                          <Link to={careerUrl} className="menu-link">
                            <i className="icon-user-plus"></i>Careers
                          </Link>
                        </li>
                        <li>
                          <Link to="/about/" className="menu-link dropdown">
                            <i className="icon-about"></i>About
                            <div class="dropdown-content">
                              <ul> 
                                <li>
                                  <Link to={`/about/`}>About Us</Link>
                                </li> 
                                <li>
                                  <Link to={`/giving-back/`}>Giving Back</Link>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* Main Nav */}
                    <Nav className="d-none d-lg-block main-nav">
                      <ul>

                        {menus &&
                          menus.map((item, i) => (
                            <li className={`${item.Add_Sub_Menus.length > 0 ? "dropdown-item-link" : ''}`}>
                              {item.Link ? <GenerateLink link={item.Link}>{item.Label}</GenerateLink> : 
                              <a href="javascript:void(0);">{item.Label}</a> }
                              {item.Add_Sub_Menus &&
                                <MegaMenu data={item} />
                              }
                            </li>
                          )
                          )}
                      </ul>
                    </Nav>
                    <div className="menu-wrap d-lg-none">
                      <button className="hamburger" onClick={showMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>
                    </div>

                  </div>

                </div>
                {/* Navigation */}
                <div
                  className={
                    show
                      ? " navigation-wrap active justify-content-center"
                      : " navigation-wrap justify-content-center"
                  }
                >
                  <div className="navigation-title">
                    <span>menu</span>
                    <Link to="#" className="close-btn" onClick={closeMenu}>
                      <span>close</span> <i className="icon-close"></i>
                    </Link>
                  </div>

                  <Nav>
                    <div className="navigation">
                      <div className="menu-accordian">
                        <ul>
                          {state.map((item, i) => (
                            <MenuData
                              key={i}
                              index={i}
                              handlerOpenMenu={handlerOpenMenu}
                              handlerOpenSubMenu={handlerOpenSubMenu}
                              item={item}
                            />
                          ))}
                        </ul>
                        <div className="second-nav">
                          <ul>
                            {/* <li className="card-header">
                            <Link to="#">Valuations</Link>
                          </li> */}
                            <li className="card-header">
                              <Link to={process.env.GATSBY_CAREER_SITE_URL}>Careers</Link>
                            </li>
                            <li className="card-header">
                              <Link to="/about/">About</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </Nav>

                </div>
              </Nav>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};

export default Header;
