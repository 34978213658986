import React from "react";
import GenerateLink from "../common/site/generate-link"
const Menudata = (props) => {
  return (
    <li
      className={
        props.item.submenu && props.item.isActive
          ? "active card-header card-header-parent"
          : "card-header card-header-parent"
      } 
    >
     <div className="d-flex align-items-center mobile-menu-dropdown-wrapper">
       {props.item.link ?
        <GenerateLink link={props.item.link} class={props.item.submenu ? "" : "no-submenu"}>
          {props.item.name}
        </GenerateLink> :
        <a href="javascript:void(0);">
          {props.item.name}
        </a>
      }        
        <div onClick={() => props.handlerOpenMenu(props.index)} className="mobile-menu-dropdown">
          {props.item.submenu && props.item.isActive ? (
            <i className="icon-arrow-up icon-arrow-down"></i>
          ) : (
            props.item.submenu && <i className="icon-arrow-down"></i>
          )}
        </div>
        
      </div>
      {props.item.submenu && props.item.isActive && (
        <ul className="submenu-list">
          {props.item.submenu.map((item, i) => (
            <li
              className={
                item.submenu && item.isActive
                  ? "active card-header card-header-child"
                  : "card-header card-header-child"
              }
              onClick={(e) =>
                props.handlerOpenSubMenu(e, props.index, i, "submenu")
              }
            >
            <GenerateLink link={item.link}>
                {item.name}

                {item.submenu && item.isActive ? (
                  <i className="icon-arrow-up"></i>
                ) : (
                  item.submenu && <i className="icon-arrow-down"></i>
                )}
              </GenerateLink>
              {item.submenu && item.isActive && (
                <ul className="submenu-list">
                  {item.submenu.map((item) => (
                    <li
                      onClick={(e) => e.stopPropagation()}
                      className="menu-accordian"
                    >
                      <a href="#">{item}</a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default Menudata;
